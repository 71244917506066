<template>
	<div :style="buyBg">
		<Header :title="$t('home.buy')"></Header>

		<div class="column-center-content main-content use" style="margin-top: 54px;">
			<div class="column-center-content main-content" style="margin-top: 20px;">
				<div v-show="false" class="row-content buy-content">
					<!-- <div @click="notForSale()"></div> -->
					<router-link to="/buyPbt" class="column-center-content shadow-box-content">
						<img src="../../assets/home/buy/pbt.png" style="width: auto; height: 50%; margin-bottom: 5px;" />
						<label class="gray-text-title">{{$t('assets.buyPbt')}}</label>
					</router-link>
					
					<router-link to="/buyWdt" class="column-center-content shadow-box-content">
						<img src="../../assets/wdt.jpg" style="width: auto; height: 50%; margin-bottom: 5px;" />
						<label class="gray-text-title">{{$t('assets.buyWdt')}}</label>
					</router-link>
					
					<!-- <router-link to="/buyBitMall" class="column-center-content shadow-box-content">
						<img src="../../assets/bitMall.png" style="width: auto; height: 50%; margin-bottom: 5px;" />
						<label class="gray-text-title">{{$t('assets.buyBitMall')}}</label>
					</router-link> -->
				</div>

				<div v-show="false" class="row-content buy-content" style="margin-top: 20px;">
					<router-link to="/buyAmbg" class="column-center-content shadow-box-content">
						<img src="../../assets/login/ambc-logo2-color.png" style="width: auto; height: 50%; margin-bottom: 5px;" />
						<label class="gray-text-title">{{$t('assets.buyAmbg')}}</label>
					</router-link>
					<!-- <router-link to="/buyNasdaq" class="column-center-content shadow-box-content">
						<img src="../../assets/home/nasdaq-logo.png" style="width: auto; height: 50%; margin-bottom: 5px;" />
						<label class="gray-text-title">{{$t('assets.buyNasdaq')}}</label>
					</router-link> -->
				</div>

				<div v-show="false" class="row-content buy-content" style="margin-top: 20px;">
					<router-link to="/buyHK" class="column-center-content shadow-box-content">
						<img src="../../assets/home/hongkong-logo.png" style="width: auto; height: 50%; margin-bottom: 5px;" />
						<label class="gray-text-title">{{$t('assets.buyHk')}}</label>
					</router-link>
					<router-link to="/buyAmbc" class="column-center-content shadow-box-content">
						<img src="../../assets/home/ambc-logo.jpg" style="width: auto; height: 50%; margin-bottom: 5px;" />
						<label class="gray-text-title">{{$t('assets.buyAmbc')}}</label>
					</router-link>
					<!--<router-link to="/buyAmbg" class="column-center-content shadow-box-content">
						<img src="../../assets/login/ambc-logo2-color.png" style="width: auto; height: 50%; margin-bottom: 5px;" />
						<label class="gray-text-title">{{$t('assets.buyAmbg')}}</label>
					</router-link> -->
					<!-- <router-link to="" class="column-center-content shadow-box-content" style="background-color: transparent; box-shadow: #c3c3c3 0px 0px 0px"></router-link> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import Header from '../../components/NavHeader.vue'
	import {
		Toast
	} from 'mint-ui';

	export default {
		name: 'Buy',
		components: {
			Header
		},
		watch: {
			selected(val) {
				// 标签栏变化，把selected的值存到sessionStorage，保存当前值
				sessionStorage.setItem('buy_selected', JSON.stringify(val))
			}
		},
		data: () => ({
			buyBg: {
				height: "100vh",
				width: "100%%",
				backgroundImage: "url(" + require("../../assets/login/new-bg.png") + ")",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "fixed",
				backgroundSize: "100% 100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				overflow: "auto"
			},
			selected: sessionStorage.getItem('buy_selected') ? JSON.parse(sessionStorage.getItem('buy_selected')) : '1',
		}),
		methods: {
			notForSale() {
				Toast({
					message: this.$i18n.t("common.notSale"),
					position: 'bottom'
				});
			}
		}
	}
</script>

<style>
	.buy-content {
		height: 200px;
		width: 100%;
		max-width: 600px
	}

	/* tab切换 */
	.use .mint-navbar {
		align-items: center;
		width: 240px;
		margin: 0 auto;
	}

	/* 设置切换后样式 */
	.use .mint-navbar .mint-tab-item.is-selected {
		border-bottom: 3px solid #009245;
		color: #009245;
		margin: 0;
	}

	/* 设置切换前样式 */
	.use .mint-navbar .mint-tab-item {
		padding: 15px 0;
		color: #000000;
		border-bottom: 3px solid #FFFFFF;
	}
</style>
